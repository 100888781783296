export default function Configs() {

    const tableColumns = [
        {
            label: "传感器名称",
            prop: "name",
            align: "center",
            show: true
        },
        {
            label: "传感器类型",
            name: 'type',
            align: "center",
            show: true
        },
				{
				    label: "操作",
				    name:'custom',
				    align: "center",
				    show: true
				},
    ];
    return {
        tableColumns,
    };
}
