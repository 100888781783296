<template>
	<div class="single-sys-common single-sys-user">
		<div class="mb-2">
			<el-button class="btn-primary" @click="add()">新增</el-button>
		</div>
		<div class="table-wrapper">
			<c-table
				:data="pageData"
				:tableColumns="tableColumns"
				v-loading="pageLoading"
				:stripe="true"
				:pagination="pageParams"
				@change="pageGet"
			>
			<template v-slot:custom="{ row }">
				<el-button type="text" @click="getDetail(row.id)">详情</el-button>
				<el-button type="text" @click="getStationDetail(row.id,row)">测点详情</el-button>
				<el-button type="text" @click="changeEnabled(row.id,0)" v-if="row.enabled == 1">禁用</el-button>
				<el-button type="text" @click="changeEnabled(row.id,1)" v-else>启动</el-button>
				<el-button type="text" @click="addItem(row.id,row)" v-if="row.enabled !== 1">添加</el-button>
				<el-button type="text" @click="update(row.id)" v-if="row.enabled !== 1">编辑</el-button>
				<el-button type="text" @click="deleteById(row.id)" v-if="row.enabled !== 1">删除</el-button>
				<el-dropdown>
					<span class="el-dropdown-link">
						更多<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item @click.native="generate(row)">生成索力解算表</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</template>
			</c-table>
		</div>
		<el-dialog
				title="生成索力解算表"
				:visible.sync="sensorVisible"
				:close-on-click-modal="false"
				width="700px"
				:before-close="sensorClose">
			<div class="form-wrapper manage-scroll">
				<el-checkbox-group v-model="sensorList">
					<el-checkbox v-for="(sensor,s) in sensorAllList" :key="s" :label="sensor.id">{{sensor.name}}</el-checkbox>
				</el-checkbox-group>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="sensorClose()">取 消</el-button>
				<el-button type="primary" @click="saveGenerate()">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog
			:title="sensorDialog.title"
			:visible.sync="sensorDialog.visible"
			:close-on-click-modal="false"
			width="1200px"
			:before-close="handleClose">
			<div class="form-wrapper manage-scroll">
				<el-form ref="form" :model="form" :rules="sensorDialog.rules" label-width="120px">
					<el-row :gutter="20" class="w-full">
						<el-col :span="12">
							<el-form-item  label="传感器名称" prop="name">
								<el-input :disabled="isDetail" v-model="form.name"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="传感器表名" prop="tableName">
								<el-input :disabled="isDetail" v-model="form.tableName"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="一级预警上限" prop="firstWarnUpper">
								<el-input :disabled="isDetail" v-model="form.firstWarnUpper"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="一级预警下限" prop="firstWarnFloor">
								<el-input :disabled="isDetail" v-model="form.firstWarnFloor"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="二级预警上限" prop="secondWarnUpper">
								<el-input :disabled="isDetail" v-model="form.secondWarnUpper"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="二级预警下限" prop="secondWarnFloor">
								<el-input :disabled="isDetail" v-model="form.secondWarnFloor"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="三级预警上限" prop="thirdWarnUpper">
								<el-input :disabled="isDetail" v-model="form.thirdWarnUpper"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="三级预警下限" prop="thirdWarnFloor">
								<el-input :disabled="isDetail" v-model="form.thirdWarnFloor"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="频率" prop="frequency">
								<el-input :disabled="isDetail" v-model="form.frequency"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="特征值选项" prop="checkList">
								<el-checkbox-group v-model="form.checkList" :disabled="isDetail">
									<el-checkbox v-for="(item,index) of checkList" :key="index" :label="item"></el-checkbox>
								</el-checkbox-group>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item  label="端口" prop="port">
								<el-input :disabled="isDetail" v-model="form.port"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="物理量" prop="physicalQuantity">
								<el-input :disabled="isDetail" v-model="form.physicalQuantity"/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="分区开始时间" prop="partitionStartTime">
								<el-date-picker
										:disabled="isDetail"
										v-model="form.partitionStartTime"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="分区结束时间" prop="partitionEndTime">
								<el-date-picker
										:disabled="isDetail"
										v-model="form.partitionEndTime"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer" v-if="!isDetail">
				<el-button @click="handleClose()">取 消</el-button>
				<el-button type="primary" @click="saveOrUpdate('form')">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog
			:title="itemDialog.title"
			:visible.sync="itemDialog.visible"
			width="1080px"
			:close-on-click-modal="false"
			:before-close="handleCloseItem">
			<div class="form-wrapper manage-scroll">
				<el-card class="form-card" v-for="(item,index) in itemFormList" :key="index">
					<el-form :ref="'itemFormList' + index" :model="item" :rules="itemDialog.rules" label-width="100px">
						<el-row :gutter="20" class="w-full">
							<el-col :span="8">
								<el-form-item  label="监测点名称" prop="name">
									<el-input :disabled="isStation" v-model="item.name"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="字段名" prop="columnName">
									<el-input :disabled="isStation || item.disableText" v-model="item.columnName"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="环境名称" prop="subName">
									<el-input :disabled="isStation" v-model="item.subName"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="传感器类型" prop="type">
									<el-select :disabled="isStation" v-model="item.type" placeholder="请选择" @change="changeType(index,item)">
										<el-option v-for="(type,t) in typeList" :key="t" :label="type.name" :value="type.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="监测项" prop="monitorDictId">
									<el-cascader :disabled="isStation" :options="monitorDictList" @change="changeType(index,item)" :props="dict" v-model="item.monitorDictId" :show-all-levels="false"></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="8" v-if="item.type && item.monitorDictId">
								<el-form-item label="位置" prop="poisition">
									<el-cascader :disabled="isStation" :options="positionList[index].list" :props="poDict" v-model="item.poisition" :show-all-levels="false"></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="单位" prop="unit">
									<el-input :disabled="isStation" v-model="item.unit"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="里程" prop="mileage">
									<el-input :disabled="isStation" type="number" v-model="item.mileage"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="一级预警上限" prop="firstWarnUpper">
									<el-input :disabled="isStation" v-model="item.firstWarnUpper"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="一级预警下限" prop="firstWarnFloor">
									<el-input :disabled="isStation" v-model="item.firstWarnFloor"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="二级预警上限" prop="secondWarnUpper">
									<el-input :disabled="isStation" v-model="item.secondWarnUpper"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="二级预警下限" prop="secondWarnFloor">
									<el-input :disabled="isStation" v-model="item.secondWarnFloor"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item  label="三级预警上限" prop="thirdWarnUpper">
									<el-input :disabled="isStation" v-model="item.thirdWarnUpper"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="三级预警下限" prop="thirdWarnFloor">
									<el-input :disabled="isStation" v-model="item.thirdWarnFloor"/>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="jsonKey" prop="sensorKey">
									<el-input :disabled="isStation" v-model="item.sensorKey"/>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<div class="q-form-footer" v-if="!isStation">
						<el-button type="primary" @click="saveItem('itemFormList' + index,index)">确 定</el-button>
						<el-button type="primary" @click="addFormItem()" v-if="index === itemFormList.length - 1">添 加</el-button>
						<el-button type="danger" @click="closeCard(index,item)">删 除</el-button>
					</div>
				</el-card>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCloseItem()">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>

import Configs from "./config/monitor.js";
export default {
  data() {
		let { tableColumns } = Configs(this);
    return {
			tableColumns,
			pageLoading:false,
			pageData: [],
			monitorDictList:[],
			dict:{
				emitPath:false,
				value:'id',
				label:'name'
			},
			poDict:{
				emitPath:false,
				value:'value',
				label:'name'
			},
			pageParams:{
				pageNum: 1,
				pageSize: 10,
				total: 0
			},
			sensorVisible:false,
			sensorAllList:[],
			sensorList:[],
			sensorDialog:{ 
				title: "新增",
				visible: false,
				rules:{
					name: [
						{ required: true, message: '请填写传感器名称', trigger: 'blur' }
					],
					tableName: [
						{ required: true, message: '请填写传感器表名', trigger: 'blur' }
					],
					port: [
						{ required: true, message: '请填写端口', trigger: 'blur' }
					],
					physicalQuantity: [
						{ required: true, message: '请填写物理量', trigger: 'blur' }
					],
				}
			},
			itemDialog:{
				title: "新增",
				visible: false,
				rules:{
					name: [
						{ required: true, message: '请填写监测点名称', trigger: 'blur' }
					],
					columnName: [
						{ required: true, message: '请填写字段名', trigger: 'blur' }
					],
					monitorDictId: [
						{ required: true, message: '请填写检测项', trigger: 'blur' }
					],
					sensorKey: [
						{ required: true, message: '请填写jsonKey', trigger: 'blur' }
					]
				}
			},
			form:{
				id: null,
				name: null,
				tableName: null,
				enabled: null,
				firstWarnUpper: null,
				firstWarnFloor: null,
				secondWarnUpper: null,
				secondWarnFloor: null,
				thirdWarnUpper: null,
				thirdWarnFloor: null,
				frequency: null,
				feature: null,
				port: null,
				monitorItem: null,
				physicalQuantity: null,
				checkList:[]
			},
			checkList:['1小时均方根','10分钟均方根','1分钟均方根','1小时最值、均值','10分钟最值、均值','1分钟最值、均值'],
			itemFormList:[{
				id: null,
				columnName: null,
				firstWarnUpper: null,
				firstWarnFloor: null,
				secondWarnUpper: null,
				secondWarnFloor: null,
				thirdWarnUpper: null,
				thirdWarnFloor: null,
				sensorTypeId: null,
				name: null,
				sensorKey: null,
				monitorDictId: null,
				disableText:false,
				type:null,
				poisition:null,
				mileage:null,
				subName:null,
				unit:null
			}],
			selectId: null,
			typeList:[],
			positionList:[{list:[]}],
			current:null,
			isDetail:false,
			isStation:false
		};
  },
	created() {
		this.pageGet();
		this.getDictList();
		this.getTypeList()
	},
	methods: {
		getDetail(id){
			this.isDetail = true
			this.update(id)
		},
  	// 获取监测项列表
		getDictList(){
			this.$http.get("/monitorDict/getTreeList").then(res=>{
				if(res.success){
					if (res.data){
						this.monitorDictList = res.data
					}
				}
			})
		},
		getTypeList(){
			this.$http.get("/sensorDetail/getSensorDetailTypeDict").then(res=>{
				if(res.success){
					if (res.data){
						this.typeList = res.data
					}
				}
			})
		},
		changeType(i,data){
			if (data.monitorDictId && data.type){
				this.$http.get("/sensorDetail/getPositionList",{params:{monitorDictId:data.monitorDictId,type:data.type}}).then(res=>{
					if(res.success){
						if (res.data){
							this.positionList[i].list = res.data
						}
					}
				})
			}
		},
		//生成索力结算表
		generate(data){
			this.current = data
			this.$http.get("/sensorType/getSensorDetailListByType",{params:{id:data.id,type:'VWA'}}).then(res=>{
				if(res.success){
					if (res.data){
						this.sensorAllList = res.data
						this.sensorVisible = true
					}
				}
			})
		},
		saveGenerate(){
			if (this.sensorList.length === 0){
				this.$message.warning('请先选择传感器')
				return
			}
			let text = ''
			this.$http.get("/sensorType/existCableResolve",{
				params:{
					id: this.current.id
				}
			}).then(res=>{
				if (res.success){
					if (res.data === false){
						text = '是否确定生成索力结算表'
					} else {
						text = '是否覆盖索力结算表'
					}
					this.submitGenerate(text)
				}
			})
		},
		submitGenerate(text){
			this.$confirm(text, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/sensorType/generateCableResolve",{
					id: this.current.id,
					sensorDetailIdList:this.sensorList
				}).then(res=>{
					if (res.success){
						this.$message.success('生成成功')
						this.sensorVisible = false
					}
				})
			}).catch(() => {});
		},
		sensorClose(){
			this.sensorList = []
			this.sensorVisible = false
		},
		//新增
		add(){
			this.sensorDialog.title = "新增";
			this.sensorDialog.visible = true;
		},
		update(id){
			this.sensorDialog.title = "编辑";
			this.$http.get("/sensorType/getById",{
				params:{
					id: id
				}
			}).then(res=>{
				if(res.success){
					if (!res.data.feature){
						res.data.checkList = []
					} else {
						// 十进制转二进制
						let checkText = res.data.feature.toString(2)
						checkText = checkText.split("")
						res.data.checkList = []
						for(let i = 0; i < checkText.length;i++){
							if (checkText[i] == 1){
								res.data.checkList.push(this.checkList[i])
							}
						}
					}
					this.form = res.data;
					this.sensorDialog.visible = true;
				}
			})
		},
		deleteById(id){
			const that = this;
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.get("/sensorType/deleteById",{
					params:{
						id: id
					}
				}).then(res=>{
					if(res.success){
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.pageGet();
					}
				})
			})
		},
		pageGet(){
			this.pageLoading = true;
			this.$http.post("/sensorType/page",this.pageParams).then(res=>{
				if(res.success){
					this.pageData = res.data.list;
					this.pageParams.total = res.data.total;
				}
				this.pageLoading = false;
			})
		},
		saveOrUpdate(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let checkText = []
					this.checkList.forEach(item => {
						if (this.form.checkList.indexOf(item) === -1){
							checkText.push(0)
						} else {
							checkText.push(1)
						}
					})
					checkText = checkText.join('')
					// 二进制转十进制
					this.form.feature = parseInt(checkText,2)
					let url = "/sensorType/add";
					if(this.form.id){
						url = "/sensorType/update";
					}
					this.$http.post(url,this.form).then(res=>{
						if(res.success){
							this.$message.success("操作成功");
							this.pageGet();
							this.handleClose()
						}
					});
				}
			})
		},
		//启用或禁用
		changeEnabled(id,enabled){
			this.$http.get("/sensorType/changeEnabled",{
				params:{
					id: id,
					enabled: enabled
				}
			}).then(res=>{
				if(res.success){
					this.$message.success("操作成功");
					this.pageGet();
				}
			})
		},
		//弹框关闭回调
		handleClose(){
			if (this.isDetail === true){
				this.isDetail = false
			}
			this.sensorDialog.visible = false;
			this.clearForm();
		},
		clearForm(){
			this.form = {
				id: null,
				name: null,
				tableName: null,
				enabled: null,
				firstWarnUpper: null,
				firstWarnFloor: null,
				secondWarnUpper: null,
				secondWarnFloor: null,
				thirdWarnUpper: null,
				thirdWarnFloor: null,
				frequency: null,
				feature: null,
				port: null,
				monitorItem: null,
				physicalQuantity: null,
				checkList: []
			}
		},
		getStationDetail(id,data){
			this.isStation = true
			this.addItem(id,data)
		},
		addItem(id,data){
		  this.current = data
			this.selectId = id;
			this.positionList = []
			this.$http.get("/sensorDetail/getListBySensorTypeId",{
				params:{
					sensorTypeId: id
				}
			}).then(res=>{
				if(res.success){
					if (res.data && res.data.length > 0){
						for (let i = 0;i < res.data.length;i++){
							res.data[i].disableText = true
							this.positionList.push({list:[]})
							this.changeType(i,res.data[i])
						}
						this.itemFormList = res.data
					} else {
						this.itemFormList = [{
							id: null,
							columnName: null,
							firstWarnUpper: data.firstWarnUpper,
							firstWarnFloor: data.firstWarnFloor,
							secondWarnUpper: data.secondWarnUpper,
							secondWarnFloor: data.secondWarnFloor,
							thirdWarnUpper: data.thirdWarnUpper,
							thirdWarnFloor: data.thirdWarnFloor,
							sensorTypeId: null,
							name: null,
							sensorKey: null,
							disableText:false,
							type:null,
							poisition:null,
							mileage:null,
							subName:null,
							unit:null
						}]
						this.positionList = [{list:[]}]
					}
					this.itemDialog.visible = true;
				}
			})
		},
		addFormItem(){
			this.itemFormList.push(
					{
						id: null,
						columnName: null,
						firstWarnUpper: null,
						firstWarnFloor: null,
						secondWarnUpper: null,
						secondWarnFloor: null,
						thirdWarnUpper: null,
						thirdWarnFloor: null,
						sensorTypeId: null,
						name: null,
						sensorKey: null,
						monitorDictId: null,
						disableText:false,
						type:null,
						poisition:null,
						mileage:null,
						subName:null,
						unit:null
					}
			)
			this.positionList.push({list:[]})
		},
		closeCard(i,data){
			this.$http.get("/sensorDetail/deleteById",{
				params: {
					id: data.id
				}
			}).then(res=>{
				if(res.success){
					this.itemFormList.splice(i, 1)
					this.positionList.splice(i, 1)
				}
			})
		},
		updateItem(id){
			this.$http.get("/sensorDetail/getListBySensorTypeId",{
				params:{
					sensorTypeId: id
				}
			}).then(res=>{
				if(res.success){
					this.selectId = id;
					this.itemFormList = res.data;
					if(this.itemFormList == null || this.itemFormList.length == 0){
						this.itemFormList = [{
							id: null,
							columnName: null,
							firstWarnUpper: null,
							firstWarnFloor: null,
							secondWarnUpper: null,
							secondWarnFloor: null,
							thirdWarnUpper: null,
							thirdWarnFloor: null,
							sensorTypeId: null,
							name: null,
							pointer: null,
						}]
					}
					this.itemDialog.visible = true;
				}
			})
		},
		saveItem(formName,index){
			this.$refs[formName][0].validate((valid) => {
				if (valid) {
					let api = ''
					let params = {}
					if (this.itemFormList[index].id){
						api = '/sensorDetail/update'
						params = this.itemFormList[index];
					} else {
						api = '/sensorType/addDetailList'
						params = {
							id: this.selectId,
							sensorDetailList: [this.itemFormList[index]]
						};
					}
					this.$http.post(api,params).then(res=>{
						if(res.success){
							this.$message.success("操作成功");
							// this.selectId = null;
							// this.resetItemFormList();
							// this.handleCloseItem();
              this.addItem(this.current.id,this.current)
						}
					})
				}
			})
		},
		handleCloseItem(){
			if (this.isStation === true){
				this.isStation = false
			}
			this.itemDialog.visible = false;
			this.selectId = null;
			this.resetItemFormList();
		},
		
		resetItemFormList(){
			this.itemFormList = [{
				id: null,
				columnName: null,
				firstWarnUpper: null,
				firstWarnFloor: null,
				secondWarnUpper: null,
				secondWarnFloor: null,
				thirdWarnUpper: null,
				thirdWarnFloor: null,
				sensorTypeId: null,
				name: null,
				sensorKey: null,
				monitorDictId: null,
				type:null,
				poisition:null,
				mileage:null,
				subName:null,
				unit:null
			}]
		}
	},
};
</script>

<style scoped>
	.form-card{
		position: relative;
		margin-bottom: 10px;
	}
	.el-dialog__footer{
		padding-right: 45px;
	}
	.el-dropdown-link {
		cursor: pointer;
		color: #409EFF;
		margin-left: 10px;
	}
	.el-icon-arrow-down {
		font-size: 12px;
	}
	/deep/ .el-date-editor.el-input{
		width: 100%;
	}
</style>
